"use client";

import { useState, useEffect } from "react";

interface IntroNoiseProps {
  /** Callback to tell the parent when IntroNoise is fully removed */
  onDone?: () => void;
}

export default function IntroNoise({ onDone }: IntroNoiseProps) {
  // Controls whether the noise is expanded (scale-y-100) or collapsed (scale-y-0)
  const [expanded, setExpanded] = useState(false);
  // Controls whether we still show the text message
  const [visible, setVisible] = useState(true);
  // Once removed, we return null
  const [removed, setRemoved] = useState(false);

  useEffect(() => {
    // Expand almost immediately on mount
    const expandTimer = setTimeout(() => {
      setExpanded(true);
    }, 50); // small delay to ensure the transition runs

    // After 3 seconds, hide text + collapse
    const collapseTimer = setTimeout(() => {
      setVisible(false);
      setExpanded(false);
    }, 4000);

    return () => {
      clearTimeout(expandTimer);
      clearTimeout(collapseTimer);
    };
  }, []);

  // Once the transition ends (collapsing back to scale-y-0),
  // we setRemoved = true → render nothing → call onDone()
  const handleTransitionEnd = () => {
    // If we just collapsed
    if (!expanded) {
      setRemoved(true);
      onDone?.();
    }
  };

  // If removed, render nothing
  if (removed) return null;

  return (
    <div
      onTransitionEnd={handleTransitionEnd}
      className={`
      top-0 lg:top-[80px] w-full lg:w-[220px] p-6 text-gray-300 absolute lg:-left-[220px]
        overflow-hidden
        transition-transform duration-700
        origin-center
        ${expanded ? "scale-y-100" : "scale-y-0"}
      `}
    >
      <video
        src="https://res.cloudinary.com/dtb9hpgez/video/upload/v1742605510/miscellaneous/white-noise-edit-3_wosu1l.mov"
        autoPlay
        loop
        muted
        playsInline
        // For older iOS browsers:
        webkit-playsinline="true"
        // disable PiP and native controls:
        disablePictureInPicture
        controls={false}
        className="w-full h-auto opacity-60"
      />

      {/* Only render text while visible */}
      {visible && (
        <div className="absolute inset-0 flex items-center justify-center">
          <p className="text-center  font-bold">
            Starting <br />
            transmission...
          </p>
        </div>
      )}
    </div>
  );
}
