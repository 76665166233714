import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  hideOverflow?: boolean;
  children: React.ReactNode;
};

export default function Overlay({
  open,
  setOpen,
  hideOverflow = true,
  children,
}: Props) {
  return (
    <>
      {/* @ts-ignore */}
      <Transition.Root show={open} as={Fragment}>
        {/* @ts-ignore */}
        <Dialog as="div" className="relative z-50" onClose={setOpen}>
          {/* @ts-ignore */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gradient-to-r from-dark-gray/80 via-dark-gray/90 to-dark-gray/80 backdrop-blur-[2px] transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-top mt-16 sm:mt-0 justify-center text-center sm:items-center sm:p-0">
              {/* @ts-ignore */}
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                {/* @ts-ignore */}
                <Dialog.Panel
                  className={`w-auto  relative transform ${hideOverflow && "overflow-hidden"} sm:p-6 rounded-2xl px-4 pb-4 pt-5 text-left transition-all sm:my-8 `}
                >
                  <div className="mt-4" />
                  {children}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
