"use client";
import { Dispatch, FormEvent, MouseEvent, SetStateAction } from "react";
import { RPCError } from "magic-sdk";
import showToast from "@/utils/showToast";
import { validateHuman } from "@/server/actions/user/recaptcha";
import { handleUserLogin } from "@/server/actions/user/loginAndReg";
import { saveUserInfo } from "@/utils/common";

function generateRodeyoUsername() {
  // e.g. "rodeyo-92tz"
  const randomStr = Math.random().toString(36).slice(2, 6);
  return `rodeyo-${randomStr}`;
}

/**
 * Try passkey login if local nickname is found. Otherwise, return {showNicknameModal: true}.
 */
export async function handleMagicWebAuthn(
  e: MouseEvent<HTMLButtonElement> | FormEvent<HTMLFormElement> | undefined,
  setLoginInProgress: Dispatch<SetStateAction<boolean>>,
  magic: any,
  setOpenLoginModal: (open: boolean) => void,
  setOpenNewUserTourModal: (open: boolean) => void,
  recaptcha: string,
  fingerprint?: string
): Promise<{ showNicknameModal: boolean }> {
  e?.preventDefault();
  try {
    setLoginInProgress(true);

    // 1) Validate reCAPTCHA
    const isHuman = await validateHuman(recaptcha);
    if (!isHuman) {
      throw new Error("reCAPTCHA validation failed.");
    }

    // 2) Check local storage for passkey nickname
    const storedUsername = localStorage.getItem("rodeyo-username");
    if (!storedUsername) {
      // Return a signal to show the nickname modal
      return { showNicknameModal: true };
    }

    // 3) Attempt passkey login
    let didToken: string | null = null;
    try {
      didToken = await magic.webauthn.login({ username: storedUsername });
    } catch (loginError) {
      console.warn(
        "Passkey login failed; registering a new passkey...",
        loginError
      );
      // If login fails, try registering a brand new passkey with a random name
      const randomUsername = generateRodeyoUsername();
      localStorage.setItem("rodeyo-username", randomUsername);

      didToken = await magic.webauthn.registerNewUser({
        username: randomUsername,
      });
    }

    if (!didToken) {
      throw new Error("No DID token returned from Magic passkey flow.");
    }

    // 4) Finalize the passkey flow
    await finalizePasskeyFlow(
      didToken,
      storedUsername,
      magic,
      setOpenLoginModal,
      setOpenNewUserTourModal,
      recaptcha,
      fingerprint
    );

    return { showNicknameModal: false };
  } catch (err: any) {
    if (err instanceof RPCError) {
      showToast({
        message: "Something went wrong with the passkey flow.",
        type: "error",
      });
    } else {
      showToast({ message: err.message || "Unknown error", type: "error" });
    }
    return { showNicknameModal: false };
  } finally {
    setLoginInProgress(false);
  }
}

/**
 * Helper to finalize passkey login or registration:
 *  - fetch user info from Magic
 *  - save DID token locally
 *  - call server's handleUserLogin
 *  - if new user, show alert
 */
export async function finalizePasskeyFlow(
  didToken: string,
  nickname: string | null,
  magic: any,
  setOpenLoginModal: (open: boolean) => void,
  setOpenNewUserTourModal: (open: boolean) => void,
  recaptcha: string,
  fingerprint?: string
) {
  // 1) Get user info from Magic
  const metadata = await magic.user.getInfo();
  const publicAddress = metadata.publicAddress || "passkey_user";

  // 2) Save user info locally
  saveUserInfo(didToken, "WEBAUTHN", publicAddress);

  // 3) Call server
  await handleUserLogin({
    didToken,
    recaptcha,
    fingerprint,
    passkeyUsername: nickname || undefined,
  });

  // 4) If new user, prompt them to remember nickname
  setOpenLoginModal(false);

  // setOpenNewUserTourModal(true);
}
