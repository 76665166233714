export const eth = "/currency/eth.png";
export const matic = "/currency/matic.png";
export const pol = "/currency/pol.png";
export const usdc = "/currency/usdc.png";
export const weth = "/currency/weth.png";
export const prime = "/currency/prime.png";

// export const usdt = "/currency/usdt.png";
// export const dai = "/currency/dai.png";
// export const wbtc = "/currency/wbtc.png";
// export const sushi = "/currency/sushi.png";
// export const uni = "/currency/uni.png";
// export const link = "/currency/link.png";
// export const aave = "/currency/aave.png";
// export const crv = "/currency/crv.png";
// export const comp = "/currency/comp.png";
// export const snx = "/currency/snx.png";
// export const yfi = "/currency/yfi.png";
// export const bal = "/currency/bal.png";

export const currencyLogos = {
  eth,
  pol,
  matic,
  usdc,
  weth,
  prime,
};

// CoinGecko id formatting
export const currencyIds = [
  {
    id: "polygon-ecosystem-token",
    symbol: "pol",
    name: "POL (ex-MATIC)",
  },
  {
    id: "usd-coin",
    symbol: "usdc",
    name: "USDC",
  },
  { id: "ethereum", symbol: "eth", name: "Ethereum" },
  { id: "matic-network", symbol: "matic", name: "Polygon" },
  { id: "arbitrum", symbol: "arb", name: "Arbitrum" },
  { id: "binance-bitcoin", symbol: "btcb", name: "Binance Bitcoin" },
  { id: "bitcoin", symbol: "btc", name: "Bitcoin" },
  { id: "optimism", symbol: "op", name: "Optimism" },
  { id: "solana", symbol: "sol", name: "Solana" },
  { id: "ripple", symbol: "xrp", name: "XRP" },
  { id: "dogecoin", symbol: "doge", name: "Dogecoin" },
  { id: "tether", symbol: "usdt", name: "Tether" },
  { id: "cardano", symbol: "ada", name: "Cardano" },
  { id: "shiba-inu", symbol: "shib", name: "Shiba Inu" },
  { id: "the-sandbox", symbol: "sand", name: "The Sandbox" },
  {
    id: "echelon-prime",
    symbol: "prime",
    name: "Echelon Prime",
  },
  {
    id: "solana",
    symbol: "sol",
    name: "Solana",
  },
  {
    id: "binancecoin",
    symbol: "bnb",
    name: "BNB",
  },
  {
    id: "flow",
    symbol: "flow",
    name: "Flow",
  },
  {
    id: "immutable-x",
    symbol: "imx",
    name: "Immutable",
  },
  {
    id: "avalanche-2",
    symbol: "avax",
    name: "Avalanche",
  },
  {
    id: "gala",
    symbol: "gala",
    name: "GALA",
  },
  {
    id: "enjincoin",
    symbol: "enj",
    name: "Enjin Coin",
  },
  {
    id: "axie-infinity",
    symbol: "axs",
    name: "Axie Infinity",
  },
  {
    id: "decentraland",
    symbol: "mana",
    name: "Decentraland",
  },
  {
    id: "illuvium",
    symbol: "ilv",
    name: "Illuvium",
  },
  {
    id: "star-atlas",
    symbol: "atlas",
    name: "Star Atlas",
  },
  {
    id: "rarible",
    symbol: "rari",
    name: "RARI",
  },
  {
    id: "my-neighbor-alice",
    symbol: "alice",
    name: "My Neighbor Alice",
  },
  {
    id: "starlink",
    symbol: "starl",
    name: "StarLink",
  },
  {
    id: "gods-unchained",
    symbol: "gods",
    name: "Gods Unchained",
  },
  {
    id: "chromaway",
    symbol: "chr",
    name: "Chromia",
  },
  {
    id: "ultra",
    symbol: "uos",
    name: "Ultra",
  },
  {
    id: "alien-worlds",
    symbol: "tlm",
    name: "Alien Worlds",
  },
  {
    id: "vulcan-forged",
    symbol: "pyr",
    name: "Vulcan Forged",
  },
  {
    id: "yield-guild-games",
    symbol: "ygg",
    name: "Yield Guild Games",
  },
  {
    id: "gensokishis-metaverse",
    symbol: "mv",
    name: "GensoKishi Metaverse",
  },
  {
    id: "starsharks",
    symbol: "sss",
    name: "StarSharks",
  },
  {
    id: "wax",
    symbol: "waxp",
    name: "WAX",
  },
  {
    id: "mobox",
    symbol: "mbox",
    name: "Mobox",
  },
  {
    id: "metahero",
    symbol: "hero",
    name: "Metahero",
  },
  {
    id: "redfox-labs-2",
    symbol: "rfox",
    name: "RFOX",
  },
  {
    id: "phantasma",
    symbol: "soul",
    name: "Phantasma",
  },
  {
    id: "nft-worlds",
    symbol: "wrld",
    name: "NFT Worlds",
  },
  {
    id: "revomon-2",
    symbol: "revo",
    name: "Revomon",
  },
  {
    id: "chain-guardians",
    symbol: "cgg",
    name: "Chain Guardians",
  },
  {
    id: "efinity",
    symbol: "efi",
    name: "Efinity",
  },
  {
    id: "sipher",
    symbol: "sipher",
    name: "SIPHER",
  },
  {
    id: "bridged-usdc-polygon-pos-bridge",
    symbol: "usdc.e",
    name: "Bridged USDC (Polygon PoS Bridge)",
  },
  {
    id: "berachain-bera",
    symbol: "bera",
    name: "Berachain",
  },
];
