"use client";
import { WebAuthnExtension } from "@magic-ext/webauthn";
import { Magic as MagicBase, SupportedLocale } from "magic-sdk";
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useAccount } from "wagmi";
import Web3 from "web3";

export type Magic = MagicBase;

type MagicContextType = {
  magic: Magic | null;
  web3: Web3 | null;
  switchNetwork: (rpcUrl: string, chainId: number) => Promise<void>;
  currentChainId: number | null;
  isNetworkSwitching: boolean;
};

const MagicContext = createContext<MagicContextType>({
  magic: null,
  web3: null,
  switchNetwork: async () => {},
  currentChainId: null,
  isNetworkSwitching: false,
});

export const useMagic = () => useContext(MagicContext);

const MagicProvider = ({ children }: { children: ReactNode }) => {
  const [magic, setMagic] = useState<Magic | null>(null);
  const [web3, setWeb3] = useState<Web3 | null>(null);
  const [currentChainId, setCurrentChainId] = useState<number | null>(null);

  // NEW: Track whether we're switching networks
  const [isNetworkSwitching, setIsNetworkSwitching] = useState(false);

  useEffect(() => {
    initializeMagic();
  }, []);

  // Detect the user's preferred language
  const getUserLocale = () => navigator.language || "en";
  const { isConnected } = useAccount();

  const initializeMagic = async (
    rpcUrl: string = `https://polygon-mainnet.infura.io/v3/${process.env.NEXT_PUBLIC_INFURA_KEY}`,
    chainId: number = 137
  ) => {
    if (isConnected) {
      return;
    }
    try {
      if (!isConnected) {
        const userLocale = getUserLocale() as SupportedLocale;
        const magic = new MagicBase(
          process.env.NEXT_PUBLIC_MAGIC_API_KEY as string,
          {
            locale: userLocale,
            network: { rpcUrl, chainId },
            extensions: [new WebAuthnExtension()],
          }
        );
        // @ts-ignore
        setMagic(magic);
        setWeb3(new Web3((magic as any).rpcProvider));
        setCurrentChainId(chainId);

        // wait for the Magic SDK to fully load
        await magic.preload();
      }
    } catch (error) {
      console.error("Failed to initialize Magic:", error);
    }
  };

  const switchNetwork = async (rpcUrl: string, chainId: number) => {
    try {
      // Start the loading state
      setIsNetworkSwitching(true);
      await initializeMagic(rpcUrl, chainId);
    } finally {
      // End the loading state (even if there's an error)
      setIsNetworkSwitching(false);
    }
  };

  const value = useMemo(
    () => ({
      magic,
      web3,
      switchNetwork,
      currentChainId,
      isNetworkSwitching, // pass it along
    }),
    [magic, web3, currentChainId, isNetworkSwitching]
  );

  return (
    <MagicContext.Provider value={value}>{children}</MagicContext.Provider>
  );
};

export default MagicProvider;
