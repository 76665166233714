import styles from "./LoadingDots.module.scss";

type Props = {
  dotStyle?: string;
  showThirdDot?: boolean;
};

export default function LoadingDots({ dotStyle, showThirdDot = true }: Props) {
  return (
    <div className={styles.loading}>
      <div className={`${styles.dot} ${dotStyle}`}></div>
      <div className={`${styles.dot}  ${dotStyle}`}></div>
      {showThirdDot && <div className={`${styles.dot}  ${dotStyle}`}></div>}
    </div>
  );
}
