"use client";
import GraphemeSplitter from "grapheme-splitter";
import { cn } from "@/lib/utils";
import { motion, stagger, useAnimate, useInView } from "motion/react";
import { useEffect } from "react";

interface WordProps {
  text: string;
  className?: string;
}

export const TypewriterEffect = ({
  words,
  className,
  onComplete, // <--- accept onComplete callback
}: {
  words: WordProps[];
  className?: string;
  onComplete?: () => void; // <--- optional callback
}) => {
  const splitter = new GraphemeSplitter();
  // Transform each word into an array of characters
  const wordsArray = words?.map((word) => {
    return {
      ...word,
      text: splitter.splitGraphemes(word.text),
    };
  });

  const [scope, animate] = useAnimate();
  const isInView = useInView(scope);

  useEffect(() => {
    if (isInView) {
      // animate() returns a promise that resolves when the animation finishes
      animate(
        "span",
        {
          display: "inline-block",
          opacity: 1,
          width: "fit-content",
        },
        {
          duration: 0.3,
          delay: stagger(0.1),
          ease: "easeInOut",
        }
      ).then(() => {
        // Once the typing animation is done, call onComplete if provided
        if (onComplete) {
          onComplete();
        }
      });
    }
  }, [isInView]);

  const renderWords = () => {
    return (
      <motion.div ref={scope} className="inline">
        {wordsArray.map((word, wIdx) => {
          return (
            <div key={`word-${wIdx}`} className="inline-block">
              {word.text.map((char, cIdx) => (
                <motion.span
                  initial={{}}
                  key={`char-${wIdx}-${cIdx}`}
                  className={cn("text-gray-300  hidden", word.className)}
                >
                  {char}
                </motion.span>
              ))}
              &nbsp;
            </div>
          );
        })}
      </motion.div>
    );
  };

  return <div className={cn("text-left", className)}>{renderWords()}</div>;
};
