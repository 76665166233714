import React, { useState } from "react";
import { boxStyles } from "./BoxStyles";
import Overlay from "@/components/ui/Overlay";

export default function Learn() {
  const topics = [
    { label: "Buy NFTs" },
    { label: "Sell NFTs" },
    { label: "Create NFTs" },
    { label: "Earn Rewards" },
    { label: "Play Games" },
    { label: "Chat with Friends" },
    { label: "Create a Wallet" },
    { label: "Buy Crypto" },
  ];

  const [overlay, setOverlay] = useState(false);

  return (
    <>
      <div className="mt-8 bg-black/50 backdrop-blur-sm p-6 pt-8 rounded-2xl mx-auto">
        <h1 className="text-white text-2xl font-semibold mb-6 text-center">
          Click a box to learn more.
        </h1>

        {/* Reduced min row height from 90px to 60px */}
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4 auto-rows-[minmax(80px,auto)] w-full">
          {topics.map((topic, index) => {
            const { classes } = boxStyles[index];
            return (
              <button
                key={topic.label}
                className={classes}
                onClick={() => setOverlay(true)}
              >
                <h3 className="text-lg font-bold text-white mb-2 flex min-w-[340px] lg:min-w-[120px]">
                  {topic.label}
                </h3>
              </button>
            );
          })}
        </div>
      </div>
      <Overlay open={overlay} setOpen={setOverlay}>
        <div className="text-3xl font-semibold"> Hello</div>
      </Overlay>
    </>
  );
}
