"use client";

import { MoveLeft, MoveRight } from "lucide-react";
import { useState, useTransition } from "react";
import { create } from "zustand";
import Overlay from "../Overlay";
import { Button } from "../button";
import Dusty from "./(components)/Dusty";
import Learn from "./(components)/Learn";
import Survey from "./(components)/Survey";
import { saveUserGameInterests } from "@/server/actions/newUserTour/saveGameInterests";

interface NewUserTourModalState {
  openNewUserTourModal: boolean;
  setOpenNewUserTourModal: (openNewUserTourModal: boolean) => void;
}

export const useNewUserTourModalStore = create<NewUserTourModalState>(
  (set) => ({
    openNewUserTourModal: true,
    setOpenNewUserTourModal: (openNewUserTourModal) =>
      set({ openNewUserTourModal }),
  })
);

export default function NewUserTourModal() {
  const { openNewUserTourModal, setOpenNewUserTourModal } =
    useNewUserTourModalStore();
  const [step, setStep] = useState(1);

  // 1) Moved the selected game genres to parent
  const [selectedGenres, setSelectedGenres] = useState<string[]>([]);
  const [isPending, startTransition] = useTransition();

  // 2) Handle the "Next" button click at step 1
  const handleNextClick = () => {
    if (step === 1) {
      // Make sure at least one genre is selected
      if (selectedGenres.length === 0) {
        alert("Please select at least one game interest before proceeding.");
        return;
      }

      startTransition(async () => {
        try {
          // Save user’s selected game interests
          await saveUserGameInterests({ genres: selectedGenres });
          // Then go to the next step
          setStep(2);
        } catch (error) {
          console.error(error);
          alert("Failed to save your selections. Please try again.");
        }
      });
    } else {
      // If already on step 2 (or beyond), just go next (or handle differently)
      setStep((prev) => prev + 1);
    }
  };

  return (
    <Overlay
      open={openNewUserTourModal}
      setOpen={setOpenNewUserTourModal}
      hideOverflow={false}
    >
      <>
        <Dusty step={step} />

        <div className="max-h-screen lg:flex lg:gap-x-3 items-center container mx-auto px-4 pb-4 -mb-[52px] pt-[224px] relative">
          {/* BACK button */}
          {step === 1 ? (
            <div />
          ) : (
            <Button
              disabled={step === 1}
              onClick={() => setStep(1)}
              className="hidden lg:flex"
            >
              <MoveLeft className="w-4" /> Back
            </Button>
          )}

          {/* Step 1 = Survey; Step 2 = Learn */}
          {step === 1 ? (
            <Survey
              selected={selectedGenres}
              setSelected={setSelectedGenres}
              isPending={isPending}
            />
          ) : (
            <Learn />
          )}

          {/* NEXT button on large screens */}
          <Button
            onClick={handleNextClick}
            disabled={isPending}
            className="hidden lg:flex"
          >
            {isPending ? "Saving..." : "Next"} <MoveRight className="w-4" />
          </Button>

          {/* On mobile, put the buttons below */}
          <div className="mt-3 w-full flex lg:hidden items-center justify-between">
            {step === 1 ? (
              <div />
            ) : (
              <Button disabled={step === 1} onClick={() => setStep(1)}>
                <MoveLeft className="w-4" /> Back
              </Button>
            )}
            <Button onClick={handleNextClick} disabled={isPending}>
              {isPending ? "Saving..." : "Next"} <MoveRight className="w-4" />
            </Button>
          </div>
        </div>
      </>
    </Overlay>
  );
}
