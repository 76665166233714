"use client";
import { useAlertStore } from "@/components/ui/Alert";
import DividerWithText from "@/components/ui/Divider/DividerWithText";
import LoadingDots from "@/components/ui/Loading/LoadingDots";
import { useNewUserTourModalStore } from "@/components/ui/NewUserTourModal";
import Terms from "@/components/ui/Terms";
import { useVisitorData } from "@fingerprintjs/fingerprintjs-pro-react";
import { motion } from "framer-motion";
import { Fingerprint } from "lucide-react";
import { FormEvent, MouseEvent, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useMagic } from "../../../../context/MagicProvider";
import PrimaryButton from "../../../ui/Forms/Button/PrimaryBtn";
import { BtnRainbowKit } from "../BtnRainbowKit";
import { handleMagicLogin } from "../handleMagicLogin";
import { handleMagicWebAuthn } from "../handleMagicWebAuthn";
import WebAuthnNickname from "./(components)/WebAuthnNickname";
import { useLoginModalStore } from "./ModalWrapper";

export default function LoginForm() {
  const { magic } = useMagic();

  const { data } = useVisitorData(
    { extendedResult: true },
    { immediate: true }
  );

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);

  const [isLoginInProgress, setLoginInProgress] = useState(false);
  const [loadingPasskey, setLoadingPasskey] = useState(false);

  const { setOpenLoginModal } = useLoginModalStore();
  const { setOpenNewUserTourModal } = useNewUserTourModalStore();
  const reRef = useRef<ReCAPTCHA>(null);

  const { setAlertType, setOpenAlert, setAlertMessage } = useAlertStore();

  // State for the nested "nickname" modal
  const [showNicknameModal, setShowNicknameModal] = useState(false);

  // ========== PASSKEY CLICK HANDLER ==========
  async function onPasskeyClick(e: MouseEvent<HTMLButtonElement>) {
    try {
      e.preventDefault();
      setLoginInProgress(true);
      setLoadingPasskey(true);

      // 1) reCAPTCHA
      const recaptcha = await reRef.current?.executeAsync();
      reRef.current?.reset();

      // 2) Attempt passkey flow
      const result = await handleMagicWebAuthn(
        e,
        setLoginInProgress,
        magic,
        setOpenLoginModal,
        setOpenNewUserTourModal,
        recaptcha || "",
        data?.visitorId
      );

      // If there's no local passkey nickname, show the nickname modal
      if (result.showNicknameModal) {
        setShowNicknameModal(true);
      }
    } catch (err) {
      console.error("Error during passkey flow:", err);
      setAlertType("error");
      setAlertMessage("Something went wrong. Please try again.");
      setOpenAlert(true);
    } finally {
      setLoginInProgress(false);
      setLoadingPasskey(false);
    }
  }

  // ========== EMAIL SUBMIT FLOW ==========
  async function onEmailSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    try {
      const recaptcha = await reRef.current?.executeAsync();
      reRef.current?.reset();

      handleMagicLogin(
        e,
        email,
        setEmail,
        setEmailError,
        setLoginInProgress,
        magic,
        setOpenLoginModal,
        setOpenNewUserTourModal,
        recaptcha || "",
        data?.visitorId
      );
    } catch (err) {
      console.error("Email login error:", err);
      setAlertType("error");
      setAlertMessage("Email login failed. Please try again.");
      setOpenAlert(true);
    }
  }

  return (
    <>
      {/* RainbowKit (wallet) button */}
      <div className="px-4">
        <BtnRainbowKit fingerprint={data?.visitorId || ""} />
      </div>

      {/* PASSKEY LOGIN BUTTON */}
      {/* <div className="px-4 mb-2 mt-6 hidden lg:flex">
        <PrimaryButton
          type="button"
          style="w-full"
          disabled={isLoginInProgress}
          loading={false}
          onClick={onPasskeyClick}
        >
          <Fingerprint className="w-6 mr-2" />
          <span className="w-[180px]">
            {loadingPasskey ? <LoadingDots /> : "Continue with Passkey"}
          </span>
        </PrimaryButton>
      </div> */}

      <DividerWithText text="or" />

      {/* EMAIL LOGIN FLOW */}
      <form
        className="grid gap-y-[12px] mt-4 mb-[12px] px-4"
        onSubmit={onEmailSubmit}
      >
        <ReCAPTCHA
          size="invisible"
          sitekey={process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA_SITE_KEY || ""}
          ref={reRef}
          className="hidden"
        />

        <div>
          <input
            disabled={isLoginInProgress}
            type="email"
            name="email"
            id="email"
            onChange={(e) => {
              if (emailError) setEmailError(false);
              setEmail(e.target.value);
            }}
            placeholder="mostly@harmless.com"
            value={email}
            className="w-full flex-1 outline-none rounded-md border-2 border-white/20 bg-white/10 px-3.5 py-3 
            text-white shadow-sm hover:border-dark-pri focus:border-dark-pri sm:leading-6"
          />
        </div>

        {emailError && <span className="error">Enter a valid email</span>}

        {email && email.length > 0 && (
          <motion.div
            whileInView={{ opacity: 1, y: 1 }}
            initial={{ opacity: 0 }}
            transition={{ duration: 1 }}
            className="w-full"
          >
            <PrimaryButton
              type="submit"
              loading={isLoginInProgress}
              disabled={isLoginInProgress || !email || email.length === 0}
              style="w-full"
            >
              <span className="py-0.5 md:py-0">Continue with email</span>
            </PrimaryButton>
          </motion.div>
        )}
      </form>

      <Terms clickText="Login / Sign up" />

      {/* ========== NICKNAME MODAL (NESTED) ========== */}
      {/* {showNicknameModal && (
        <WebAuthnNickname
          showNicknameModal={showNicknameModal}
          setShowNicknameModal={setShowNicknameModal}
          isLoginInProgress={isLoginInProgress}
          setLoginInProgress={setLoginInProgress}
          setOpenLoginModal={setOpenLoginModal}
          reRef={reRef}
          magic={magic}
          data={data}
        />
      )} */}
    </>
  );
}
