import Image from "next/image";
import styles from "./styles.module.scss";
import DialogManager from "./Dialog";

type Props = {
  step: number;
};

export default function Dusty({ step }: Props) {
  return (
    <>
      {/* UFO Image */}
      <Image
        src="/ufo-no-bg.png"
        width={2000}
        height={2000}
        alt="dusty on a fence"
        className={`
       z-30 w-[500px]
       -mt-[150px]
       top-0
       h-auto
       mx-auto
       object-contain
       absolute
       left-1/2
       -translate-x-1/2
       ${styles.sway}
     `}
      />
      {/* Dusty Image */}
      <Image
        src="/dusty-on-a-fence-3.png"
        width={400}
        height={400}
        alt="dusty on a fence"
        className={`
       z-30
       w-[240px]
       mt-4
       h-auto
       mx-auto
       object-contain
       absolute
       top-0
       left-1/2
       -translate-x-1/2
       ${styles.sway}
     `}
      />
      <div className="absolute right-0 top-4">
        <DialogManager step={step} />
      </div>
    </>
  );
}
