"use client";

import { useState } from "react";
import { useMagic } from "@/context/MagicProvider";
import { logout } from "@/utils/common";
import { useDisconnect } from "wagmi";
import { useRouter } from "next/navigation";
import { logoutUser } from "@/server/actions/user/auth/logout";

/**
 * useLogout encapsulates the entire logout process, including:
 * - WAGMI disconnect
 * - Router redirect
 * - Local storage cleanup
 * - Magic logout
 * - Server-side logout action
 */
export function useLogout() {
  const { magic } = useMagic();
  const { disconnect } = useDisconnect();
  const router = useRouter();

  const [loggingOut, setLoggingOut] = useState(false);

  const handleLogout = async () => {
    try {
      setLoggingOut(true);
      disconnect(); // from wagmi
      await logoutUser();
      if (magic !== null) {
        await logout(magic);
      }
      localStorage.removeItem("avatar");
      localStorage.removeItem("user");
      localStorage.removeItem("token");
    } catch (error) {
    } finally {
      setLoggingOut(false);
      router.push("/");
    }
  };

  return {
    handleLogout,
    loggingOut,
  };
}
