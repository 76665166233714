export const boxStyles = [
  {
    // Box #1
    classes: `
        md:col-span-2 md:row-span-2
        bg-gradient-to-br
        from-[#1951f9]
        to-[#9E4AE0]
        rounded-xl shadow-lg p-4
        hover:shadow-xl hover:ring-2 hover:ring-purple-400
        transition-all duration-300 !min-w-[200px]
      `,
  },
  {
    // Box #2
    classes: `
        bg-gradient-to-br
        from-pink
        to-[#EE9337]
        rounded-xl shadow-lg p-4
        hover:shadow-xl hover:ring-2 hover:ring-pink-neon
        transition-all duration-300
      `,
  },
  {
    // Box #3
    classes: `
        bg-gradient-to-t
        from-[#c4f31a]
        to-[#d59515]
        rounded-xl shadow-lg p-4
        hover:shadow-xl hover:ring-2 hover:ring-[#d59515]
        transition-all duration-300
      `,
  },
  {
    // Box #4 (no longer row-span-2)
    classes: `
        bg-gradient-to-br
        from-[#49f5f5]
        to-[#099ecb]
        rounded-xl shadow-lg p-4
        hover:shadow-xl hover:ring-2 hover:ring-[#49f5f5]
        transition-all duration-300
      `,
  },
  {
    // Box #5
    classes: `
        bg-gradient-to-br
        from-primary
        to-[#7F39AD]
        rounded-xl shadow-lg p-4
        hover:shadow-xl hover:ring-2 hover:ring-purple-400
        transition-all duration-300
      `,
  },
  {
    // Box #6
    classes: `
        md:col-span-2
        bg-gradient-to-br
        from-[#2F7C31]
        via-[#39A73B]
        to-[#3FAE49]
        rounded-xl shadow-lg p-4
        hover:shadow-xl hover:ring-2 hover:ring-green-400
        transition-all duration-300
      `,
  },
  {
    // Box #7
    classes: `
        bg-gradient-to-br
        from-pink-neon
        to-dark-pri
        rounded-xl shadow-lg p-4
        hover:shadow-xl hover:ring-2 hover:ring-pink-neon
        transition-all duration-300
      `,
  },
  {
    // Box #8
    classes: `
        bg-gradient-to-br
        from-[#F55442]
        to-[#ED7E47]
        rounded-xl shadow-lg p-4
        hover:shadow-xl hover:ring-2 hover:ring-red-400
        transition-all duration-300
      `,
  },
];
