"use client";

import React, { useState } from "react";
import "./styles.css";
import { TypewriterEffect } from "@/components/ui/typewriter-effect";
import { cn } from "@/lib/utils";
import Noise from "./Noise";
import IntroNoise from "./IntroNoise";

type Props = {
  step: number;
};

// ----------------------------------
// STEP 1 DIALOGS
// ----------------------------------
const firstWords = [
  { text: "Dusty" },
  { text: "here." },
  { text: "I'll" },
  { text: "be" },
  { text: "your" },
  { text: "tour" },
  { text: "guide!" },
  { text: "✨" },
];

// const secondWords = [
//   { text: "You" },
//   { text: "can" },
//   { text: "leave" },
//   { text: "this" },
//   { text: "tour" },
//   { text: "by" },
//   { text: "clicking" },
//   { text: "on" },
//   { text: "the" },
//   { text: "gray" },
//   { text: "background." },
// ];

const thirdWords = [
  { text: "First," },
  { text: "tell" },
  { text: "me" },
  { text: "what" },
  { text: "kind" },
  { text: "of" },
  { text: "games" },
  { text: "and" },
  { text: "NFTs" },
  { text: "you're" },
  { text: "into." },
];

const fourthWords = [
  { text: "I'll" },
  { text: "show" },
  { text: "you" },
  { text: "the" },
  { text: "best" },
  { text: "stuff!" },
  { text: "As" },
  { text: "well" },
  { text: "as" },
  { text: "any" },
  { text: "promos" },
  { text: "or" },
  { text: "air" },
  { text: "drops" },
  { text: "based" },
  { text: "on" },
  { text: "what" },
  { text: "you" },
  { text: "like" },
  { text: "🎁" },
];

// ----------------------------------
// STEP 2 DIALOGS
// ----------------------------------
const fifthWords = [
  { text: "At" },
  { text: "Rodeyo," },
  { text: "you" },
  { text: "can" },
  { text: "earn" },
  { text: "rewards," },
  { text: "buy" },
  { text: "NFTs," },
  { text: "sell" },
  { text: "NFTs," },
  { text: "play" },
  { text: "games," },
  { text: "and" },
  { text: "chat" },
  { text: "with" },
  { text: "friends." },
];

const sixthWords = [
  { text: "You" },
  { text: "can" },
  { text: "also" },
  { text: "create" },
  { text: "a" },
  { text: "wallet," },
  { text: "mint" },
  { text: "your" },
  { text: "own" },
  { text: "NFTs," },
  { text: "and" },
  { text: "even" },
  { text: "buy" },
  { text: "crypto!" },
];

const seventhWords = [
  { text: "Let's" },
  { text: "explore" },
  { text: "how" },
  { text: "you" },
  { text: "can" },
  { text: "start" },
  { text: "your" },
  { text: "journey" },
  { text: "on" },
  { text: "Rodeyo." },
];

// Used as an empty or final placeholder line in both steps if needed
const endWords = [{ text: "" }];

// Group the step‑1 dialogs together
const step1Dialogs = [
  firstWords,
  // secondWords,
  thirdWords,
  fourthWords,
  endWords,
];

// Group the step‑2 dialogs together
const step2Dialogs = [fifthWords, sixthWords, seventhWords, endWords];

export default function DialogManager({ step }: Props) {
  const [introDone, setIntroDone] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [phase, setPhase] = useState<"typing" | "fading">("typing");

  // Decide which set of dialogs to use based on step
  const dialogs = step === 2 ? step2Dialogs : step1Dialogs;

  // Called by TypewriterEffect once it finishes typing one line
  const handleTypingComplete = () => {
    setPhase("fading");
  };

  // Called by the fade-out animation
  const handleAnimationEnd = () => {
    if (phase === "fading") {
      const nextIndex = currentIndex + 1;
      if (nextIndex < dialogs.length) {
        setCurrentIndex(nextIndex);
        setPhase("typing");
      } else {
        console.log("All dialogs done! Checking if we show final Noise...");
      }
    }
  };

  return (
    <div className="relative">
      {/* 1) Show IntroNoise (Starting transmission...) until it’s removed */}
      {!introDone && <IntroNoise onDone={() => setIntroDone(true)} />}

      {/* 2) After IntroNoise is done, show the typewriter dialogs */}
      {introDone && (
        <>
          <div
            className={cn(
              currentIndex < dialogs.length - 1 ? "dialog-box" : "hidden"
            )}
          >
            <div
              className={cn(phase === "typing" ? "fade-in" : "fade-out")}
              onAnimationEnd={handleAnimationEnd}
            >
              <TypewriterEffect
                key={currentIndex}
                words={dialogs[currentIndex]}
                onComplete={handleTypingComplete}
              />
            </div>
          </div>

          {/* 3) Once the last dialog is shown, display the final Noise *only* if step === 2 */}
          {/* {step === 2 && currentIndex === dialogs.length - 1 && <Noise />} */}
        </>
      )}
    </div>
  );
}
